<template>
  <DetailLayout>
    <div class="container">
      <div class="row body">
        <div class="col-12 p-0">
          <div
            class="
              card
              p-0
              border-0 border-radius-md
              overflow-hidden
              explore-image--wrapper
            "
          >
            <img
              alt="map"
              :src="require(`../../../assets/map/${id}.png`)"
              class="w-100"
            />
          </div>

          <div class="filter">
            <ApartmentFilter
              @get-filter-result="GetFilterResult"
            ></ApartmentFilter>
          </div>

          <!-- Display Result -->
          <div
            class="card d-flex flex-column gap-3 border-0"
            v-for="property in properties"
            :key="property._id"
          >
            <Card
              :property="property"
              :id="property._id"
              :title="property.title"
              :image="property.images[0]"
              :plan="property.plan"
              :address="property.address"
              :cost="property.cost"
              :utility_cost="property.utility_costs"
              :wifi_cost="property.wifi_cost"
              :area="property.square"
              :bedroom="property.bedroom"
              :bathroom="property.bathroom"
              :neighborhood="property.condition[0]"
            />
          </div>
          <b-pagination
            v-if="properties.length > 0"
            v-model="page"
            :total-rows="count"
            :per-page="size"
            prev-text="Prev"
            next-text="Next"
            @change="handlePageChange"
            align="center"
          >
          </b-pagination>
        </div>
      </div>
    </div>
  </DetailLayout>
</template>

<script>
import axiosConfig from "../../../libs/axiosConfig";
import DetailLayout from "../../../layout/main/DetailLayout.vue";
import ApartmentFilter from "./components/filter.vue";
import Card from "../../../components/utils/Card.vue";
export default {
  name: "explore",
  components: {
    ApartmentFilter,
    DetailLayout,
    Card,
  },
  created() {
    this.properties = [];
  },
  data() {
    return {
      id: this.$route.params.area_id,
      properties: [],
      filterData: [],
      page: 1,
      count: 0,
      size: 4,
    };
  },
  methods: {
    GetFilterResult(filterData) {
      this.filterData = filterData;
      axiosConfig
        .post("/explore?page=" + this.page + "&size="+this.size, this.filterData)
        .then((response) => {
          this.properties = response.data.properties;
          this.count = response.data.totalItems;
          if(this.count == 0) {
            this.$bvToast.toast('No result found', {
              title: 'No result',
              variant: 'warning',
              solid: true
            })
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    handlePageChange(value) {
      this.page = value;
      axiosConfig
        .post("/explore?page=" + this.page + "&size="+this.size, this.filterData)
        .then((response) => {
          this.properties = response.data.properties;
          this.count = response.data.totalItems;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<style></style>

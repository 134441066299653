<template>
  <div class="my-3">
    <div class="form">
      <h3
        class="
          bg-success
          text-white
          p-1
          text-uppercase text-center text-lg-left
        "
      >
        {{ $t("explore.Search") }}
      </h3>
      <b-form @submit.prevent="onSubmit" class="pb-3 px-3 my-3 form_wrapper">
        <div class="row filter__option">
          <div class="col-3">
            <div class="d-flex gap-1 align-items-center">
              <img
                src="@/assets/icons/house.png"
                alt="condition"
                class="filter_image"
              />
              <span>{{ $t("explore.Option") }}</span>
            </div>
          </div>
          <div class="col-9">
            <b-form-group>
              <b-form-select
                v-model="form.option"
                :options="options"
              ></b-form-select>

              <div v-if="form.option === 'for_rent'" class="mt-2">
                <b-form-checkbox-group
                  v-model="form.for_rent"
                  :options="for_rents"
                >
                </b-form-checkbox-group>
              </div>

              <div v-if="form.option === 'for_sale'" class="mt-2">
                <b-form-checkbox-group
                  v-model="form.for_sale"
                  :options="for_sales"
                >
                </b-form-checkbox-group>
              </div>
            </b-form-group>
          </div>
        </div>

        <div class="row filter__area">
          <div class="col-3">
            <div class="d-flex gap-1 align-items-center">
              <img
                src="@/assets/icons/area.png"
                alt="area"
                class="filter_image"
              />
              <span>{{ $t("explore.Area") }}</span>
            </div>
          </div>
          <div class="col-9">
            <b-form-group>
              <b-form-select v-model="form.area_min" :options="area_min">
              </b-form-select>
              <span class="h2">~</span>
              <b-form-select v-model="form.area_max" :options="area_max">
              </b-form-select>
            </b-form-group>
          </div>
        </div>

        <div class="row filter__cost">
          <div class="col-3">
            <div class="d-flex gap-1 align-items-center">
              <img
                src="@/assets/icons/price.png"
                alt="price"
                class="filter_image"
              />
              <span>{{ $t("explore.Cost") }}</span>
            </div>
          </div>
          <div class="col-9">
            <div class="pr-4" v-if="form.option === 'for_rent'">
              <vue-slider
                v-model="rent_range.value_1"
                :min="0"
                :max="10000"
                :interval="500"
                :marks="rent_range.marks"
                :tooltip-formatter="formatter2"
              ></vue-slider>
            </div>
            <div class="pr-4" v-if="form.option === 'for_sale'">
              <vue-slider
                v-model="sale_range.value_2"
                :min="0"
                :max="1000000"
                :interval="25000"
                :marks="sale_range.marks"
                :tooltip-formatter="formatter2"
              ></vue-slider>
            </div>
            <div class="mt-5">
              <span v-if="form.option === 'for_rent'"
                >{{ $t("explore.Rental Fee") }}
                {{ this.formatter2(rent_range.value_1[0]) }} ~
                {{ this.formatter2(rent_range.value_1[1]) }}
              </span>
              <span v-if="form.option === 'for_sale'"
                >{{ $t("explore.Buy Property Price") }}
                {{ this.formatter2(sale_range.value_2[0]) }} ~
                {{ this.formatter2(sale_range.value_2[1]) }}
              </span>
            </div>
          </div>
        </div>

        <div class="row filter__condition">
          <div class="col-3">
            <div class="d-flex gap-1 align-items-center">
              <img
                src="@/assets/icons/condition.png"
                alt="condition"
                class="filter_image"
              />
              <span>{{ $t("explore.Condition") }}</span>
            </div>
          </div>
          <div class="col-9">
            <b-form-checkbox-group
              v-model="form.condition"
              :options="conditions"
            >
            </b-form-checkbox-group>
          </div>
        </div>

        <div class="row filter__plan">
          <div class="col-3">
            <div class="d-flex gap-1 align-items-center">
              <img
                src="@/assets/icons/layout.png"
                alt="layout"
                class="filter_image"
              />
              <span> {{ $t("explore.Floor plan") }}</span>
            </div>
          </div>
          <div class="col-9">
            <b-form-checkbox-group
              v-model="form.plan"
              :options="plans"
             
            >
            </b-form-checkbox-group>
          </div>
        </div>

        <div class="d-flex justify-content-center">
          <b-button
            type="submit"
            class="text-black-50 mt-2 button-search bg-white explore-search-btn"
          >
            <img src="@/assets/icons/search.png" alt="search" />
            <span class="p-4 text-uppercase">{{ $t("explore.Search") }}</span>
          </b-button>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
// import axiosConfig from "../../../../libs/axiosConfig";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
export default {
  name: "property-filter",
  components: { VueSlider },
  data() {
    return {
      rent_range: {
        value_1: [0, 5000],
        marks: {
          0: {
            label: "$0",
          },
          10000: {
            label: "$10,000",
          },
        },
      },
      sale_range: {
        value_2: [25000, 125000],
        marks: {
          0: {
            label: "$0",
          },
          1000000: {
            label: "$1,000,000",
          },
        },
      },
      properties: [],
      form: {
        area_min: "0",
        area_max: "1000",
        cost: "5000",
        condition: [],
        option: "for_rent",
        for_rent: [],
        for_sale: [],
        plan: [],
      },
      options: [
        { value: "for_rent", text: this.$t("explore.For Rent") },
        { value: "for_sale", text: this.$t("explore.For Sale") },
      ],
      for_rents: [
        { text: this.$t("explore.rent.Apartment"), value: "apartment" },
        { text: this.$t("explore.rent.Penthouse"), value: "pent-house" },
        { text: this.$t("explore.rent.Villa"), value: "villa" },
        { text: this.$t("explore.rent.Office"), value: "office" },
      ],
      for_sales: [
        { text: this.$t("explore.sale.Apartment"), value: "apartment" },
        { text: this.$t("explore.sale.House"), value: "house" },
      ],
      plans: [
        { text: this.$t("sidebar.layout.studio"), value: "studio" },
        { text: "1LDK", value: "1ldk" },
        { text: "2LDK", value: "2ldk" },
        { text: "3LDK", value: "3ldk" },
        { text: "4LDK", value: "4ldk" },
        { text: "1DK", value: "1dk" },
        { text: "1K", value: "1k" },
        { text: "2K", value: "2k" },
        { text: "2DK", value: "2dk" }
      ],
      area_min: [
        { text: "0", value: "0" },
        { text: "50", value: "50" },
        { text: "100", value: "100" },
        { text: "150", value: "150" },
        { text: "200", value: "200" },
        { text: "250", value: "250" },
        { text: "300", value: "300" },
        { text: "350", value: "350" },
        { text: "400", value: "400" },
        { text: "450", value: "450" },
        { text: "500", value: "500" },
        { text: "600", value: "600" },
        { text: "700", value: "700" },
        { text: "800", value: "800" },
        { text: "900", value: "900" },
        { text: "1000", value: "1000" },
        { text: "1100", value: "1100" },
        { text: "1200", value: "1200" },
        { text: "1300", value: "1300" },
        { text: "1400", value: "1400" },
        { text: "1500", value: "1500" },
      ],
      area_max: [
        { text: "50", value: "50" },
        { text: "100", value: "100" },
        { text: "150", value: "150" },
        { text: "200", value: "200" },
        { text: "250", value: "250" },
        { text: "300", value: "300" },
        { text: "350", value: "350" },
        { text: "400", value: "400" },
        { text: "450", value: "450" },
        { text: "500", value: "500" },
        { text: "600", value: "600" },
        { text: "700", value: "700" },
        { text: "800", value: "800" },
        { text: "900", value: "900" },
        { text: "1000", value: "1000" },
        { text: "1100", value: "1100" },
        { text: "1200", value: "1200" },
        { text: "1300", value: "1300" },
        { text: "1400", value: "1400" },
        { text: "1500", value: "1500" },
        { text: "1500+", value: "100000" },
      ],

      conditions: [
        { value: "convenient-store", text: this.$t("property_create.Convenient-store") },
        { value: "supermarket", text: this.$t("property_create.Supermarket") },
        { value: "market", text: this.$t("property_create.Market") },
        { value: "bus-stop", text: this.$t("property_create.Bus Stop") },
        { value: "hospital", text: this.$t("property_create.Hospital") },
        { value: "school", text: this.$t("property_create.School") },
        {
          value: "auto-locking-system",
          text: this.$t("property_create.Auto Locking System"),
        },
        { value: "sea-view", text: this.$t("property_create.Sea View") },
        { value: "pool", text: this.$t("property_create.Pool") },
        { value: "gym", text: this.$t("property_create.Gym") },
        {
          value: "building-mgr",
          text: this.$t("property_create.Building Mgr"),
        },
        {
          value: "international-school",
          text: this.$t("property_create.International School"),
        },
        { value: "pet-allowed", text: this.$t("property_create.Pet Allowed") },
        {
          value: "buy/sell-allowed",
          text: this.$t("property_create.Buy/Sell Allowed"),
        },
      ],
    };
  },

  methods: {
    formatter2: (v) => `$${("" + v).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
    onSubmit() {
      const url = window.location.href;
      var filterData = {
        sector: parseInt(url.substr(url.length - 1)),
        type:
          this.form.option == "for_rent"
            ? this.form.for_rent
            : this.form.for_sale,
        option: this.form.option,
        square_min: parseInt(this.form.area_min),
        square_max: parseInt(this.form.area_max),
        min_cost:
          this.form.option == "for_rent"
            ? parseInt(this.rent_range.value_1[0])
            : parseInt(this.sale_range.value_2[0]),
        max_cost:
          this.form.option == "for_rent"
            ? parseInt(this.rent_range.value_1[1])
            : parseInt(this.sale_range.value_2[1]),
        condition: this.form.condition,
        plan: this.form.plan,
      };
      if (filterData.type.length == 0) {
        filterData.type =
          filterData.option == "for_rent"
            ? this.for_rents.map((item) => item.value)
            : this.for_sales.map((item) => item.value);
      }
      if (filterData.condition.length == 0)
        filterData.condition = this.conditions.map((item) => item.value);
      if (filterData.plan.length == 0)
        filterData.plan = this.plans.map((item) => item.value);
      this.$emit("get-filter-result", filterData);
    },
  },
};
</script>

<style lang="scss">
.filter__condition .bv-no-focus-ring {
  display: flex;
  flex-direction: column;
}
.gap-1 {
  gap: 10px;
}
.filter__plan .bv-no-focus-ring {
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 1fr 1fr;
}
.filter__equipment .bv-no-focus-ring {
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: repeat(3, 1fr);
}
.form_wrapper {
  border-bottom: 2px solid var(--success);
  .filter_image {
    max-width: 35px;
    max-height: 40px;
  }
  .col-3 {
    white-space: nowrap;
    font-weight: bold;
  }
  .col-3,
  .col-9 {
    border-bottom: 1px dashed var(--success);
    padding: 15px;
  }
}

.filter__area {
  .form-group .col-9 {
    display: flex;
  }
  .form-group div {
    display: flex;
    gap: 10px;
    .custom-select {
      max-width: 100px;
    }
  }
}
.explore-search-btn {
  border-radius: 0 !important;
  // background: linear-gradient(
  //   -180deg,
  //   #f1f2f2 0% 6.06%,
  //   #e6e7e8 6.06% 80.9%,
  //   #d1d3d4 80.9% 94%,
  //   #bcbec0 94% 100%
  // );
  background: linear-gradient(-180deg, #f1f2f2 0% 6.06%, #e6e7e8 6.06% 100%);

  span {
    font-weight: 700 !important;
    letter-spacing: 0.7rem;
    color: black !important;
  }
}

@media screen and (min-width: 992px) {
  .filter__condition .bv-no-focus-ring {
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: repeat(5, 1fr);
  }

  .filter__plan .bv-no-focus-ring {
    grid-auto-flow: column;
    grid-template-rows: 1fr 1fr;
  }
}
@media screen and (max-width: 992px) {
  .form_wrapper {
    .filter_image {
      max-width: 25px !important;
    }
    .col-9 {
      padding-right: 0 !important;
    }
    .col-3 {
      font-size: 0.8rem;
    }
  }
}
</style>
